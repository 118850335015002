import { Fragment } from 'react';
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CampgroundMapImg from '../../../assets/CampgroundMap.jpg';
import CampgroundMapImgEnglish from '../../../assets/CampgroundMapEnglish.jpg';

import i18n from '../../../i18n/i18n';

const CampgroundMap = () => {
  const language = useSelector((state) => state.localization.language);
  const { t } = i18n;

  return(
    <Fragment>
      <Divider sx={{mt: {xs:5, md:10}, mb:4}} id='plan-du-site'>
        <Stack>
          <Typography variant='h5'>
            {t('campgroundMap.secondary')}
          </Typography>
          <Typography 
              variant='h4'
              sx={{
                  letterSpacing:'-0.05em',
                  fontWeight:600,
              }}
          >
            {t('campgroundMap.main')}
          </Typography>
        </Stack>
      </Divider>
      <Container maxWidth='xl'>
        <Box 
          sx={{
            display:'flex',
            justifyContent:'center',
            width:'100%'
          }}
        >
          <Stack>
            <Box 
              sx={{
                width:'100%',
                justifyContent:'center',
                alignItems:'center',
                textAlign:'center',
              }}
            >
              <Box
                component="img"
                alt="plan du site"
                src={language === 'fr' ? CampgroundMapImg : CampgroundMapImgEnglish}
                sx={{
                    width:{xs:'100%',md:'80%'}
                }}
              />
            </Box>
          </Stack>
        </Box>
    </Container>
    </Fragment>
  );
}

export default CampgroundMap;