import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import ListenToScroll from "./components/scroll/ListenToScroll";
import Routes from './routes';

import Theme from './layout/Theme';

const App = () => {

  return (
    <ThemeProvider theme={Theme}>
      <ListenToScroll />
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
