import React from "react";
import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Tooltip from '@mui/material/Tooltip';

import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from '@mui/icons-material/Email';

import Navigation from './navigation'

const NavAppBar = () =>
{
  const scrollThresholdPassed = useSelector((state) => state.theme.scrollThresholdPassed)

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar 
          position="fixed"
          elevation={scrollThresholdPassed ? 1 : 0}
          sx={{zIndex: "modal", bgcolor: scrollThresholdPassed ? 'common.white' : 'transparent'}}
      >
          <Toolbar
            component="nav"
            sx={{ justifyContent: "space-between"}}
          >
            <Stack direction="row" spacing={1}>
              <Tooltip title="Facebook">
                <IconButton aria-label="facebook" component={Link} href="https://www.facebook.com/groups/1406399446229913">
                  <FacebookIcon sx={{
                    fontSize:"1.5em",
                    color:scrollThresholdPassed ? 'common.black' : 'common.white',
                  }}/>
                </IconButton>
              </Tooltip>

              <Tooltip title="418-851-2403">
                <IconButton aria-label="telephone" component={Link} href="tel:418-851-2403">
                  <LocalPhoneIcon sx={{
                      fontSize:"1.5em",
                      color:scrollThresholdPassed ? 'common.black' : 'common.white',
                  }}/>
                </IconButton>
              </Tooltip>

              <Tooltip title="campingplage3p@gmail.com">
                <IconButton aria-label="email" component={Link} href="mailto:campingplage3p@gmail.com">
                  <EmailIcon sx={{
                      fontSize:"1.5em",
                      color:scrollThresholdPassed ? 'common.black' : 'common.white',
                  }}/>
                </IconButton>
              </Tooltip>
            </Stack>

            <Box sx={{ flexGrow: 1 }} />

            <Navigation />
          </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavAppBar;