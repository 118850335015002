import * as React from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AttractionsIcon from '@mui/icons-material/Attractions';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import TodayIcon from '@mui/icons-material/Today';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import BedroomBabyOutlinedIcon from '@mui/icons-material/BedroomBabyOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

import i18n from '../../../../../i18n/i18n';

const Activities = () => {
    const { t } = i18n;

    return(
        <Grid container spacing={2} sx={{mt:4}}>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <BeachAccessIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                    {t('activities.beach')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <WaterDropIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.waterplayground')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <TodayIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.eventsAndAnimations')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <AttractionsIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.playground')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <SportsHockeyIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.hockeyRink')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <SportsTennisIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.tableTennis')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <SportsVolleyballIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.beachVolleyball')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={6} md={4} justifyContent="center">
                <Stack>
                    <BedroomBabyOutlinedIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.horseshoeGames')}
                    </Typography>
                </Stack>
            </Grid>
            <Grid container item xs={12} md={4} justifyContent="center">
                <Stack>
                    <CircleOutlinedIcon fontSize='large'sx={{ width:'100%'}}/>
                    <Typography align='center'>
                        {t('activities.petanque')}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default Activities;