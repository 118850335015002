import { createSlice } from '@reduxjs/toolkit';
import { enUS } from '@mui/material/locale';

const initialState = {
  scrollThresholdPassed : false,
  localization: enUS,
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateScrollThreshold: (state, action) =>{
      state.scrollThresholdPassed = action.payload
    },
    updateLocalization: (state, action) =>{
      state.localization = action.payload
    }
  }
})

export const { toggleTheme, updateScrollThreshold, updateLocalization } = themeSlice.actions;
export default themeSlice.reducer;