import { Fragment} from 'react'
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

import Appbar from './appbar';
import Footer from './footer';

const Template = () => 
<Fragment>
    <Appbar />
    <Box sx={{p:0}}>
        <main>
            {/* <Box sx={theme => theme.mixins.toolbar}/> */}
            <Outlet />
        </main>
    </Box>
    <Footer />
</Fragment>

export default Template;