import { useSelector, useDispatch } from "react-redux";
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { open } from '../../redux/features/imgModalSlice'

const Img = styled('img')({});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '95%',
  maxHeight:'95%',
  bgcolor: 'background.paper',
  p: 4,
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
};

const ImgModal = () => {
  const {isOpen, src, title } = useSelector((state) => state.imgmodal);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(open(false));

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Img
          src={src}
          srcSet={src}
          alt={title}
          sx={{
            maxHeight:{xs:`${window.screen.height * 0.90}px`, md:`${window.screen.height * 0.75}px`} ,
            maxWidth: `${window.screen.width * 0.90}px`,
          }}
        />
      </Box>
    </Modal>
  );
}

export default ImgModal;