import Template from '../layout/main-layout';
import Home from '../views/home';

const MainRoutes = {
    path: '/',
    element: <Template />,
    children: [
        {
            path: '/',
            element: <Home />
        }
    ]
};

export default MainRoutes;
