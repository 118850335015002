import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import HailIcon from '@mui/icons-material/Hail';

import i18n from '../../../../i18n/i18n';

import Reception from '../../../../assets/Reception.jpg'

const Img = styled('img')({
  maxWidth: '100%',
  maxHeight: '90%',
});

const Prices = () => {
  const { t } = i18n;

  function createData(icon, services, daily, weekly, seasonal) {
    return { icon, services, daily, weekly, seasonal };
  }
  
  const rows = [
    createData(<LocalFireDepartmentIcon sx={{fontSize:'1.5em'}}/>, t('prices.noService'), '38$', '228$', ''),
    createData(<RvHookupIcon sx={{fontSize:'1.5em'}}/>, t('prices.twoServices'), '43$', '258$', ''),
    createData(<AirportShuttleIcon sx={{fontSize:'1.5em'}}/>, t('prices.threeServices'), '49$', '294$', '1700$'),
  ];

  return(
    <Container maxWidth='xl'>
      <Grid container>
        <Grid item xs={12} md={5}>
          <Img sx={{maxHeight:{xs:'100%', lg:'90%'}}} alt="Accueil" src={Reception} />
        </Grid>
        <Grid item xs={12} md={7} >
          <Stack>
            <Typography variant='h5'>
                {t('prices.secondary')}
            </Typography>
            <Typography 
                variant='h4'
                sx={{
                    letterSpacing:'-0.05em',
                    fontWeight:600,
                    mb:2
                }}
            >
              {t('prices.main')}
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="prices table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('prices.services')}</TableCell>
                    <TableCell align="right">{t('prices.perNight')}</TableCell>
                    <TableCell align="right">{t('prices.perWeek')}</TableCell>
                    <TableCell align="right">{t('prices.perSeason')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Box sx={{display:'flex'}}>
                          {row.icon}
                          <Typography fontSize='1.1em' sx={{ml:1}}>
                            {row.services}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">{row.daily}</TableCell>
                      <TableCell align="right">{row.weekly}</TableCell>
                      <TableCell align="right">{row.seasonal}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component={Paper} sx={{my:1}}>
              <Table aria-label="prices table">
                <TableBody>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Box sx={{display:'flex'}}>
                          <HailIcon />
                          <Typography fontSize='1.1em' sx={{ml:1}}>
                            {t('prices.guests')}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">{t('prices.children')} : 3$</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">{t('prices.adults')} : 5$</TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}


export default Prices;