import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const Loader = () =>
<Box sx={{
        position:'fixed',
        top: 0,
        left: 0,
        zIndex: 'snackbar',
        width:'100%',
    }}
>
    <LinearProgress color="primary" />
</Box>

export default Loader;
