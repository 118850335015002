import React, { lazy } from 'react';
import Loadable from '../ui-components/Loadable';

import Template from '../layout/main-layout';

const NotFound = Loadable(lazy(() => import('../views/notfound')));

const NotFoundRoute = {
    path: '*',
    element: <Template />,
    children: [
        {
            path: '*',
            element: <NotFound />
        }
    ]
};

export default NotFoundRoute;
