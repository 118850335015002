import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../i18n/i18n';
import { defaultLang, supportedLangs } from '../../components/const/Localization';

const initialState = {
  language : i18n.resolvedLanguage || defaultLang,
  supportedLangs: supportedLangs,
}

export const localizationSlice = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
        if(state.supportedLangs.includes(action.payload))
        {
          state.language = action.payload;
        }
    }
  }
})

export const { setLanguage } = localizationSlice.actions;
export default localizationSlice.reducer;