import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentSection : '',
  pageTitle:''
}

export const SectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    updateSection: (state, action) => {
      state.currentSection = action.payload.section;
      state.pageTitle = action.payload.title;
    }
  }
})

export const { updateSection } = SectionsSlice.actions;
export default SectionsSlice.reducer;