import { configureStore } from '@reduxjs/toolkit';
import themeReducer from '../features/themeSlice';
import tabsReducer from '../features/tabsSlice';
import sectionsSlice from '../features/sectionsSlice';
import imgModalSlice from '../features/imgModalSlice';
import localizationSlice from '../features/localizationSlice';


export const store = configureStore({
  reducer: {
    theme: themeReducer,
    tabs: tabsReducer,
    sections: sectionsSlice,
    imgmodal: imgModalSlice,
    localization: localizationSlice,
  }
});
