import React from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import logo from '../../../assets/svg/logo.svg'

const Img = styled('img')({
    
});


const Waves = ({title, to='#', subItem=<Box />, dense=false} ,props) => 
<Box 
    sx={{
        position:'relative',
        display:'flex',
        justifyContent: 'center',
        alignItems:'center',
        flexDirection: 'column',
        minHeight: { xs: "100vh", sm: dense ? "60vh": '80vh' },
        overflow:'hidden',
        animationName:'rotate',
        animationIterationCount:'infinite',
        animationTimingFunction:'linear',
        '&:before, &:after':{
            content: '""',
            position: 'absolute',
            left: '50%',
            minWidth: '300vw',
            minHeight: '300vw',
            backgroundImage: 'linear-gradient(90deg, rgba(195,198,181,1) 0%, rgba(218,211,190,1) 15%, rgba(225,216,192,1) 75%, rgba(237,221,188,1) 100%)',
            animationName: 'waves',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
        },
        '&:before': {
            bottom: '12vh',
            borderRadius: '45%',
            animationDuration: '25s',
        },
        '&:after': {
            bottom: '8vh',
            opacity: '.5',
            borderRadius: '47%',
            animationDuration: '20s',
        },
        '@keyframes waves ': {
            '0%': {transform: 'translate(-50%, 0) rotateZ(0deg)'},
            '50%': {transform: 'translate(-50%, -2%) rotateZ(180deg)'},
            '100%': {transform: 'translate(-50%, 0%) rotateZ(360deg)'}
        }
}}>
    <Stack direction="column" spacing={1}>
        <Stack direction="column" spacing={1} sx={{zIndex:'10'}} alignItems='center'>
            {!dense && <Img src={logo} sx={{width:{xs:'50%', md:'30%'}}} alt="Logo Camping"/>}
            <Typography
            sx={{
                textTransform:'uppercase',
                fontSize:'3em',
                fontWeight:700,
                lineHeight:'1em',
                letterSpacing:'0.05em',
                m: '0.5em',
                zIndex: '10',
                color:'common.white',
                textAlign:'center',
            }}
            >
                {title}
            </Typography>
        </Stack>

        {subItem}
    </Stack>
</Box>

export default Waves;