import { Fragment } from "react";
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import ImgModal from '../../../components/modals/ImgModal';
import { open, updateImgInformations } from '../../../redux/features/imgModalSlice'

import i18n from '../../../i18n/i18n';

import BeachFront from '../../../assets/BeachFront.jpg';
import BeachLeft from '../../../assets/BeachLeft.jpg';
import BeachRight from '../../../assets/BeachRight.jpg';
import BikePath from '../../../assets/BikePath.jpg';
import ChristmasCamper from '../../../assets/ChristmasCamper.jpg';
import ChristmasCamperATV from '../../../assets/ChristmasCamperATV.jpg';
import Garden from '../../../assets/Garden.jpg';
import HockeyRink from '../../../assets/HockeyRink.jpg';
import Mascot from '../../../assets/Mascot.jpg';
import NoService from '../../../assets/NoService.jpg';
import NorthSites from '../../../assets/NorthSites.jpg';
import ObservationPoint from '../../../assets/ObservationPoint.jpg';
import ObservationPointSunset from '../../../assets/ObservationPointSunset.jpg';
import OrangeSunset from '../../../assets/OrangeSunset.jpg';
import Playground from '../../../assets/Playground.jpg';
import PoolAndPlayground from '../../../assets/PoolAndPlayground.jpg';
import Reception from '../../../assets/Reception.jpg';
import Sunset from '../../../assets/Sunset.jpg';
import SunSetVertical from '../../../assets/SunSetVertical.jpg';
import StLawrenceRiverBeach from '../../../assets/StLawrenceRiverBeach.jpg';

const style = {
  cursor:'pointer'
};

export default function PhotosGallery() {
  const { t } = i18n;
  const dispatch = useDispatch();
  function handleOpen(src, title) {
    dispatch(updateImgInformations({src, title}));
    dispatch(open(true));
  }

  return(
    <Fragment>
      <Divider sx={{mt: {xs:5, md:10}, mb:4}} id='photos'>
        <Stack>
          <Typography variant='h5'>
          {t('photos.secondary')}
          </Typography>
          <Typography 
              variant='h4'
              sx={{
                  letterSpacing:'-0.05em',
                  fontWeight:600,
              }}
          >
            {t('photos.main')}
          </Typography>
        </Stack>
      </Divider>
      <Container maxWidth='xl'>
        <Box sx={{ width: '100%'}}>
          <ImageList sx={{ height: 600 }} cols={4} gap={8}>
            {itemData.map((item, i) => (
              <ImageListItem key={i}>
                <img
                    src={item.img}
                    srcSet={item.img}
                    alt={item.title}
                    onClick={(e) => handleOpen(e.target.src, e.target.alt)}
                    style={style}
                  />
              </ImageListItem>
            ))}
          <ImgModal />
          </ImageList>
        </Box>
      </Container>
      <Divider sx={{mt: 5}} />
    </Fragment>
  );
}

const itemData = [
  {
    img: Reception,
    title: 'Campsites',
  },
  {
    img: PoolAndPlayground,
    title: 'Campsites',
  },
  {
    img: NorthSites,
    title: 'Sunset',
  },
  {
    img: StLawrenceRiverBeach,
    title: 'Campsites',
  },
  {
    img: ObservationPoint,
    title: 'Another Sunset',
  },
  {
    img: NoService,
    title: 'Mascot Lucky',
  },

  {
    img: HockeyRink,
    title: 'Observation Point',
  },
  {
    img: Garden,
    title: 'Christmas Camper ATV',
  },
  {
    img: Playground,
    title: 'Sunset',
  },
  {
    img: BeachLeft,
    title: 'Bike Path',
  },
  {
    img: BeachFront,
    title: 'Campsites',
  },
  {
    img: BeachRight,
    title: 'Christmas Camper train',
  },
  {
    img: ObservationPointSunset,
    title: 'Observation Point',
  },
  {
    img: OrangeSunset,
    title: 'Sunset',
  },
  {
    img: Sunset,
    title: 'Another Sunset',
  },
  {
    img: BikePath,
    title: 'Bike Path',
  },
  {
    img: SunSetVertical,
    title: 'Sunset',
  },
  {
    img: Mascot,
    title: 'Mascot Lucky',
  },
  {
    img: ChristmasCamper,
    title: 'Christmas Camper train',
  },
  {
    img: ChristmasCamperATV,
    title: 'Christmas Camper ATV',
  },
];