import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateScrollThreshold } from '../../redux/features/themeSlice';
import { updateSection } from '../../redux/features/sectionsSlice'
import pages from '../const/Pages';
import heightOffset from '../const/Offset';


const findAnchors = () => {
  let anchors = [];
  let pageLinkId = '';
  let section = '';
  let title = '';
  let el = null;

  pages().forEach(page => {
    pageLinkId = page.url.replace('/#','');
    section = page.section;
    title = page.title;
    el = page.top ? document.documentElement : document.getElementById(pageLinkId);
    el && anchors.push({pageLinkId, section, title, el});
  });

  return anchors;
}

const findAnchorsPosition = (anchors, currentSection, dispatch) => {
  let position = 0;

  anchors.forEach(anchor => {
    position = anchor.el.getBoundingClientRect().top;
    if(position >= -heightOffset - 20 && position <= heightOffset + 20){
      currentSection !== anchor.section && dispatch(updateSection({section: anchor.section, title:anchor.title}));
    }
  });
}

const ListenToScroll = () => {
  const scrollThresholdPassed = useSelector((state) => state.theme.scrollThresholdPassed);
  const currentSection = useSelector((state) => state.sections.currentSection);
  const dispatch = useDispatch();
  const anchors = useRef(null);
  const heightToHideFrom = 200;

  const listenToScroll = () => {
    
    const winScroll = document.documentElement.scrollTop;

    if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
      const lastAnchor = anchors.current[anchors.current.length - 1];
      dispatch(updateSection({section: lastAnchor.section, title:lastAnchor.title}))
    }
    else {
      findAnchorsPosition(anchors.current, currentSection, dispatch);
    }

    if (winScroll > heightToHideFrom) {
      !scrollThresholdPassed && dispatch(updateScrollThreshold(true));
    } else {
      scrollThresholdPassed && dispatch(updateScrollThreshold(false));
    }  
  };
  
  useEffect(() => {
    anchors.current = findAnchors();
    findAnchorsPosition(anchors.current, currentSection, dispatch);
    window.addEventListener("scroll", listenToScroll);
    return () => 
        window.removeEventListener("scroll", listenToScroll); 
  })
}

export default ListenToScroll;