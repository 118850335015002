import * as React from 'react';
import moment from 'moment';
import { startSeason, endSeason } from '../../../components/const/SeasonDates'

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import IconButton from "@mui/material/IconButton";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';

import i18n from '../../../i18n/i18n';

const ContactInformations = () => {
    const { t } = i18n;

    return(
    <Container maxWidth='xl' sx={{mt: {xs:5, md:10}}}>
        <Grid container>
            <Grid item xs={12} md={7} sx={{pr:{md:20}}}>
            <Stack>
                <Typography variant='h5' id='nous-joindre'>
                    {t('contactUs.secondary')}
                </Typography>
                <Typography 
                    variant='h4'
                    sx={{
                        letterSpacing:'-0.05em',
                        fontWeight:600,
                        mb:2
                    }}
                >
                {t('contactUs.main')}
                </Typography>
                <Box component={Link} underline='none' href='tel:418-851-2403' sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', my:2}}>
                    <IconButton aria-label="telephone" color='primary'>
                        <PhoneIcon sx={{fontSize:'1.5em', mb:1}}/>
                    </IconButton>
                    <Typography color='text.secondary' fontSize='1.1em' sx={{ml:1}}>418-851-2403</Typography>
                    <Typography color='text.secondary' fontSize='1.1em' sx={{ml:1}}>{t('contactUs.officeHours')}</Typography>
                </Box>
                <Box component={Link} underline='none' href='mailto:campingplage3p@gmail.com' sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', my:2}}>
                    <IconButton aria-label="email" color='primary'>
                        <EmailIcon sx={{fontSize:'1.5em', mb:1}}/>
                    </IconButton>
                    <Typography color='text.secondary' fontSize='1.1em' sx={{ml:1}}>campingplage3p@gmail.com</Typography>
                </Box>
                <Box component={Link} underline='none'
                    href='https://www.google.com/maps/place/Camping+plage+Trois-Pistoles/@48.1663431,-69.1310379,17z/data=!3m1!4b1!4m8!3m7!1s0x4cbee5bcce50e603:0xe8a0add62e0cd1d3!5m2!4m1!1i2!8m2!3d48.1663395!4d-69.1288492'
                    sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', my:2}}
                >
                    <IconButton aria-label="location" color='primary'>
                        <LocationOnIcon sx={{fontSize:'1.5em', mb:1}}/>
                    </IconButton>
                    <Typography color='text.secondary' fontSize='1.1em' sx={{ml:1}}>130 Chemin Rioux, Notre-Dame-des-Neiges, Québec, G0L4K0</Typography>
                </Box>
                <Box 
                    sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', my:2}}
                >
                    <DateRangeIcon color='primary' sx={{fontSize:'2.2em', mb:1}}/>
                    <Typography color='text.secondary' fontSize='1.1em' sx={{ml:1}}>{t('contactUs.seasonBegins')}{moment(startSeason).format('YYYY[-]MM[-]DD')}</Typography>
                    <Typography color='text.secondary' fontSize='1.1em' sx={{ml:1}}>{t('contactUs.seasonEnds')}{moment(endSeason).format('YYYY[-]MM[-]DD')}</Typography>
                </Box>
            </Stack>
            </Grid>

            <Grid item xs={12} md={5} sx={{mt:{xs:5, md:0, minHeight:'50vh'}}}>
                <iframe
                    title="Google Maps"
                    width='100%'
                    height="100%"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=130%20chemin%20rioux,%20trois-pistoles&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                    frameBorder="0"
                    scrolling="no"
                    allow="fullscreen"
                ></iframe>
            </Grid>
        </Grid>
    </Container>
    );
}

export default ContactInformations;