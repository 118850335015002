import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTab : 'activities'
}

export const TabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    updateSelectedTab: (state, action) => {
      state.currentTab = action.payload;
    }
  }
})

export const { updateSelectedTab } = TabsSlice.actions;
export default TabsSlice.reducer;